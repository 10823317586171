@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TKTypeBold-Regular";
  src: url("../public/fonts/TKTypeBold-Regular.woff2") format("woff2"),
    url("../public/fonts/TKTypeBold-Regular.woff") format("woff");
  font-display: "swap";
}

@font-face {
  font-family: "TKTypeMedium-Regular";
  src: url("../public/fonts/TKTypeMedium-Regular.woff2") format("woff2"),
    url("../public/fonts/TKTypeMedium-Regular.woff") format("woff");
  font-display: "swap";
}

@font-face {
  font-family: "TKTypeRegular-Italic";
  src: url("../public/fonts/TKTypeRegular-Italic.woff2") format("woff2"),
    url("../public/fonts/TKTypeRegular-Italic.woff") format("woff");
  font-display: "swap";
}

@font-face {
  font-family: "TKTypeRegular-Regular";
  src: url("../public/fonts/TKTypeRegular-Regular.woff2") format("woff2"),
    url("../public/fonts/TKTypeRegular-Regular.woff") format("woff");
  font-display: "swap";
}

@font-face {
  font-family: "BebasNeue-Regular";
  src: url("../public/fonts/BebasNeue-Regular.woff2") format("woff2"),
    url("../public/fonts/BebasNeue-Regular.woff") format("woff");
  font-display: "swap";
}

body,
html {
  font-family: "TKTypeRegular-Regular", Arial, Verdana, sans-serif;
  font-size: 16px;
  line-height: 26px;
  min-width: 320px;
}

a {
  @apply text-tk-brand;
}
